import { logError } from 'App/services/coralogixService';
import { getUserId } from "App/services/idpTokenService";
import { AnalyticsEvent, analyticsTrack } from 'Shared/services/analytics/config';
import { getFirebaseConfigValue } from "Shared/services/firebase/hooks/useFirebaseRemoteConfig";

const ga4ThresholdConfigKey = 'web_ga4_analytics';
const ga4OverrideConfigKey  = 'web_enable_ga4_analytics_for_user_ids';

interface ThresholdConfig {
  category : GA4_EVENT_CATEGORY;
  threshold: number;
}

enum GA4_EVENT_CATEGORY {
  verification           = 'verification',
  redemption             = 'redemption',
  survey_load            = 'survey_load',
  survey_clicks          = 'survey_clicks',
  survey_impressions     = 'survey_impressions',
  survey_impressions_adm = 'survey_impressions_adm',
  aquisition             = 'aquisition',
  survey_medley          = 'survey_medley',
  survey_qualification   = 'survey_qualification',
  survey_tracking        = 'survey_tracking',
  other                  = 'other',
  ste                    = 'ste',
  login                  = 'login',
  email                  = 'email',
  security               = 'security',
  cashback_coupons       = 'cashback_coupons',
  page_view              = 'page_view',
}

/**
 * Returns list of user ids that don't apply to the threshold rules
 * @returns number[]
 */
const getOverrideConfig = async (): Promise<number[]> => {
  const { value } = await getFirebaseConfigValue(ga4OverrideConfigKey, 'string');

  if (value) {
    try {
      const overrides = JSON.parse(value as string);

      if (Array.isArray(overrides)) {
        return overrides;
      }
    } catch (error) {
      logError('Error parsing GA4 threshold config', error);
    }
  }

  return [];
};

/**
 * Gets the event thresholds
 * @returns ThresholdConfig[]
 */
const getThresholdConfig = async (): Promise<ThresholdConfig[]> => {
  const { value } = await getFirebaseConfigValue(ga4ThresholdConfigKey, 'string');

  if (value) {
    try {
      const config = JSON.parse(value as string);

      if (config.configs) {
        return config.configs;
      }
    } catch (error) {
      logError('Error parsing GA4 threshold config', error);
    }
  }

  return [
    {
      category: 'verification' as GA4_EVENT_CATEGORY,
      threshold: 0
    },
    {
      category: 'redemption' as GA4_EVENT_CATEGORY,
      threshold: 0
    },
    {
      category: 'survey_load' as GA4_EVENT_CATEGORY,
      threshold: 0
    },
    {
      category: 'survey_clicks' as GA4_EVENT_CATEGORY,
      threshold: 0
    },
    {
      category: 'survey_impressions' as GA4_EVENT_CATEGORY,
      threshold: 0
    },
    {
      category: 'survey_impressions_adm' as GA4_EVENT_CATEGORY,
      threshold: 0
    },
    {
      category: 'aquisition' as GA4_EVENT_CATEGORY,
      threshold: 0
    },
    {
      category: 'survey_medley' as GA4_EVENT_CATEGORY,
      threshold: 0
    },
    {
      category: 'survey_qualification' as GA4_EVENT_CATEGORY,
      threshold: 0
    },
    {
      category: 'survey_tracking' as GA4_EVENT_CATEGORY,
      threshold: 0
    },
    {
      category: 'other' as GA4_EVENT_CATEGORY,
      threshold: 0
    },
    {
      category: 'ste' as GA4_EVENT_CATEGORY,
      threshold: 0
    },
    {
      category: 'login' as GA4_EVENT_CATEGORY,
      threshold: 0
    },
    {
      category: 'email' as GA4_EVENT_CATEGORY,
      threshold: 0
    },
    {
      category: 'security' as GA4_EVENT_CATEGORY,
      threshold: 0
    },
    {
      category: 'page_view' as GA4_EVENT_CATEGORY,
      threshold: 0
    }
  ];
};

/**
 * Checks whether the event can be sent based on threshold rules
 * @param category - The category of the event
 * @returns boolean
 */
const canSendGa4Event = async (category: GA4_EVENT_CATEGORY): Promise<boolean> => {
  const userIdCache = getUserId(true);
  const userId      = parseInt(userIdCache);

  if (!userIdCache || isNaN(userId)) {
    return false;
  }

  const config: ThresholdConfig[] = await getThresholdConfig();
  const userThreshold             = userId % 100;

  for (const threshold of config) {
    if (threshold.category === category) {
      if (threshold.threshold >= userThreshold) {
        return true;
      }
    }
  }

  const overrideConfig: number[] = await getOverrideConfig();
  if (overrideConfig.includes(userId)) {
    return true;
  }

  return false;
};

/**
 * Checks threshold rules and tracks the event
 * @param category - The category of the event
 * @param events - The events to track
 * @returns void
 */
const trackEventConditionally = async (category: GA4_EVENT_CATEGORY, events: AnalyticsEvent[]): Promise<void> => {
  canSendGa4Event(category).then(canSend => {
    if (canSend) {
      analyticsTrack(events);
    }
  });
};

export {
  GA4_EVENT_CATEGORY,
  trackEventConditionally
};
import { getSessionId } from 'App/services/idpTokenService';
import { SurveySortingOption } from 'Pages/surveyExperience/components/surveyList/SurveyList';
import { RepdataVerification } from 'Pages/surveyExperience/services/repdataService';
import { LeaderBoardPointsCache } from 'Shared/components/design/leaderBoard/service/leaderBoardService';
import { LOCAL_STORAGE_IDS } from 'Shared/constants';
import { ACTUserPointsResponse } from 'Shared/models/swagger/act';
import { IDPUserResponse } from 'Shared/models/swagger/idp';
import { UPMCategory, UPMQuestionsResponse } from 'Shared/models/swagger/upm';
import { VERUserVerificationStatus } from 'Shared/models/swagger/ver';
import { getLocalStorage, setLocalStorage } from 'Shared/services/localStorageService';
import { UserRedemptionOptions } from 'Shared/services/red/app/redService';

export enum UserSessionCacheKeys {
  SESSION_ID                      = 'sessionId',
  CATEGORY_DATA                   = 'categoryData',
  USER_POINTS                     = 'userPoints',
  QUESTIONS_DATA                  = 'questions',
  REDEMPTION_OPTIONS              = 'redemptionOptions',
  USER_INFO_WITH_DATES            = 'userInfoWithDates',
  USER_INFO_WITH_INVITE_ID        = 'userInfoWithInviteId',
  REPDATA_SURVEY_VERIFICATION     = 'repDataSurveyVerification',
  CLICKED_SURVEY_SUPPRESSION_LIST = 'clickedSurveySuppressionList',
  USER_VERIFICATION_STATUS        = 'userVerificationStatus',
  SURVEYS_SORTING_OPTION_SELECTED = 'surveysSortingOptionSelected',
  LEADERBOARD_POINTS              = 'leaderboardPoints'
}

type SessionCacheType = {
  [UserSessionCacheKeys.SESSION_ID]                     : string | null;
  [UserSessionCacheKeys.CATEGORY_DATA]                  : Record<string, UPMCategory>;
  [UserSessionCacheKeys.USER_POINTS]                    : ACTUserPointsResponse | undefined;
  [UserSessionCacheKeys.QUESTIONS_DATA]                 : Record<number, UPMQuestionsResponse>;
  [UserSessionCacheKeys.REDEMPTION_OPTIONS]             : UserRedemptionOptions | undefined;
  [UserSessionCacheKeys.USER_INFO_WITH_DATES]           : IDPUserResponse | null;
  [UserSessionCacheKeys.USER_INFO_WITH_INVITE_ID]       : IDPUserResponse | null;
  [UserSessionCacheKeys.REPDATA_SURVEY_VERIFICATION]    : RepdataVerification[] | null;
  [UserSessionCacheKeys.CLICKED_SURVEY_SUPPRESSION_LIST]: string[];
  [UserSessionCacheKeys.USER_VERIFICATION_STATUS]       : VERUserVerificationStatus | null;
  [UserSessionCacheKeys.SURVEYS_SORTING_OPTION_SELECTED]: SurveySortingOption | null;
  [UserSessionCacheKeys.LEADERBOARD_POINTS]             : LeaderBoardPointsCache;
}

const initialSessionCache: SessionCacheType = {
  [UserSessionCacheKeys.SESSION_ID]                     : null,
  [UserSessionCacheKeys.CATEGORY_DATA]                  : {},
  [UserSessionCacheKeys.USER_POINTS]                    : undefined,
  [UserSessionCacheKeys.QUESTIONS_DATA]                 : {},
  [UserSessionCacheKeys.REDEMPTION_OPTIONS]             : undefined,
  [UserSessionCacheKeys.USER_INFO_WITH_DATES]           : null,
  [UserSessionCacheKeys.USER_INFO_WITH_INVITE_ID]       : null,
  [UserSessionCacheKeys.REPDATA_SURVEY_VERIFICATION]    : [],
  [UserSessionCacheKeys.CLICKED_SURVEY_SUPPRESSION_LIST]: [],
  [UserSessionCacheKeys.USER_VERIFICATION_STATUS]       : null,
  [UserSessionCacheKeys.SURVEYS_SORTING_OPTION_SELECTED]: null,
  [UserSessionCacheKeys.LEADERBOARD_POINTS]             : { totalPoints: 0, updatedAt: null}
};

type CacheDataTypes = UPMCategory | ACTUserPointsResponse | UserRedemptionOptions | UPMQuestionsResponse | IDPUserResponse | RepdataVerification[] | VERUserVerificationStatus  | LeaderBoardPointsCache | SurveySortingOption | string[] | null;

/**
 * Retrieves the session cache from local storage.
 * If the session cache does not exist in local storage, it returns an empty object.
 *
 * @returns {object} The session cache from local storage if it exists, or an empty object if it does not.
 */
export const getSessionCache = (): SessionCacheType | Record<PropertyKey, never> => {
  return getLocalStorage(LOCAL_STORAGE_IDS.SESSION_CACHE, true) || {};
};

/**
 * Initializes the session cache with an empty category data cache.
 * This function should be called when creating a new session or when needing to reset the session's cache.
 */
export const initSessionCache = (): void => {
  const sessionId    = getSessionId();
  const sessionCache = getSessionCache();

  // If the session cache already exists and the session ID is the same, do nothing.
  if (sessionId === sessionCache.sessionId) {
    return;
  }

  setLocalStorage(LOCAL_STORAGE_IDS.SESSION_CACHE, {
    ...initialSessionCache,
    sessionId,
  }, true);
};

/**
 * Sets cache data for given cache key anbd optional subCache key.
 * @param {UserSessionCacheKeys} cacheKey
 * @param {CacheDataTypes}data
 * @param {string}subCacheKey
 */
export const setUserCacheData = (cacheKey: UserSessionCacheKeys, data: CacheDataTypes, subCacheKey?: string | number): void => {
  const existingCache = getLocalStorage(LOCAL_STORAGE_IDS.SESSION_CACHE, true) || {};

  // Determine the structure of the updated cache based on the presence of a subCacheKey.
  const updatedCache = subCacheKey
    ? {
        ...existingCache,
        [cacheKey]: {
          ...existingCache[cacheKey],
          [subCacheKey]: data,
        },
      }
    : {
        ...existingCache,
        [cacheKey]: data,
    };

  updatedCache.sessionId = getSessionId();

  setLocalStorage(LOCAL_STORAGE_IDS.SESSION_CACHE, updatedCache, true);
};

/**
 * Retrieves the cached data for a specific category/cache key from the session cache.
 * If the category data does not exist in the cache, it returns `undefined` to indicate the absence of cached data.
 *
 * @param {UserSessionCacheKeys} cacheKey - The unique identifier for the category whose data is being retrieved from the cache.
 * @param {string} subCacheKey - The unique identifier for the sub-category whose data is being retrieved from the cache.
 * @returns {CacheDataTypes | undefined} The cached data for the specified category if it exists, or `undefined` if there is no cached data for the category.
 */
export function getUserCacheData<TKey extends UserSessionCacheKeys> (cacheKey: TKey): SessionCacheType[TKey] | undefined
export function getUserCacheData<TKey extends UserSessionCacheKeys> (cacheKey: TKey, subCacheKey: string): SessionCacheType[TKey] extends Record<string, any> ? SessionCacheType[TKey][string] | undefined : never
export function getUserCacheData<TKey extends UserSessionCacheKeys> (cacheKey: TKey, subCacheKey: number): SessionCacheType[TKey] extends Record<number, any> ? SessionCacheType[TKey][number] | undefined : never
export function getUserCacheData (cacheKey: UserSessionCacheKeys, subCacheKey?: string | number) {
  const sessionId    = getSessionId();
  const sessionCache = getLocalStorage(LOCAL_STORAGE_IDS.SESSION_CACHE, true) || {};

  if (sessionId !== sessionCache.sessionId) {
    return undefined;
  }

  const cacheKeyData = sessionCache[cacheKey];

  return subCacheKey ? cacheKeyData?.[subCacheKey] : cacheKeyData ?? undefined;
}

import { SwrKeys } from 'Shared/services/swr/swrKeys';

/**
 * Generates dynamic cache key for SWR
 * @param key
 * @param identifier
 * @returns
 */
function generateSwrCacheKey(key: SwrKeys, identifier: (string | number)[]): (string | number)[] {
  return [key, ...identifier];
}

/**
 * Generates cache key for category data
 * @param userId
 * @returns
 */
export function getCategoryDataSwrCacheKey(categoryId: number): (string | number)[] {
  return generateSwrCacheKey(SwrKeys.CategoryData, [categoryId]);
}

/**
 * Generates cache key for hook that fetches a single question by id
 * @param questionId
 * @returns
 */
export function getQuestionCacheKey(questionId: string): (string | number)[] {
  return generateSwrCacheKey(SwrKeys.Question, [questionId]);
}

/**
 * Generates cache key for infinite loading of completed activities
 * @param timeTo
 * @param timeFrom
 * @returns
 */
export function getCompletedActivitiesCacheKey(timeFrom: string, timeTo: string, pageSize: number, currentPage: number): string[] {
  return [SwrKeys.CompletedActivities, timeFrom, timeTo, pageSize.toString(), currentPage.toString()];
}

/**
 * Generates cache key for firebase remote config
 * @param key
 * @returns
 */
export function getFirebaseRemoteConfigCacheKey(key: string): (string | number)[] {
  return generateSwrCacheKey(SwrKeys.FirebaseRemoteConfig, [key]);
}
import { fetchAndActivate, getValue } from "firebase/remote-config";
import { remoteConfig } from 'Shared/services/firebase';
import { getFirebaseRemoteConfigCacheKey } from "Shared/services/swr/swrKeyService";
import useSWR from 'swr';

type configTypes = 'boolean' | 'string' | 'number';

/**
 * Hook to get the value of a firebase remote config key
 * @param key - The key of the remote config value to get
 * @param type - The type of the remote config value to get
 * @returns The value of the remote config key
 */
const useFirebaseRemoteConfig = (key: string, type: configTypes) => {
  const swrKey = getFirebaseRemoteConfigCacheKey(key);
  return useSWR(swrKey, async () => {
    const value = await getFirebaseConfigValue(key, type);
    return value;
  });
};

/**
 * Get the value of a firebase remote config key
 * @param key - The key of the remote config value to get
 * @param type - The type of the remote config value to get
 * @returns The value of the remote config key
 */
const getFirebaseConfigValue = async (key: string, type: configTypes) => {
  await getFirebaseConfig();
  const value = getValue(remoteConfig, key);

  if (!value) {
    if (type === 'boolean') {
      return {
        value: false,
      };
    } else if (type === 'string') {
      return {
        value: '',
      };
    } else if (type === 'number') {
      return {
        value: 0,
      };
    }
  }

  if (type === 'boolean') {
    return {
      value: value.asBoolean(),
    };
  } else if (type === 'string') {
    return {
      value: value.asString(),
    };
  } else if (type === 'number') {
    return {
      value: value.asNumber(),
    };
  }

  return {
    value: value.asBoolean(),
  };
};

// Promise variable cache
let activeFirebaseRequest: null | Promise<boolean> = null;

// Wrapper for the calls to firebase to prevent concurrent calls
const getFirebaseConfig = (): Promise<boolean> | null => {
  if (activeFirebaseRequest !== null) {
    return activeFirebaseRequest;
  }

  activeFirebaseRequest = fetchAndActivate(remoteConfig);

  activeFirebaseRequest?.finally(() => {
    // Reset to null so that new promsie can be set again
    activeFirebaseRequest = null;
  });

  return activeFirebaseRequest;
};

export {
  getFirebaseConfigValue,
  useFirebaseRemoteConfig,
};
